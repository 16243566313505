import React, { useContext } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import Layout from "../Layout/Layout"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import ConsentAndAuthorization from "elements/ConsentAndAuthorization"

import { AppContext } from "../../context/AppContext"
import {
  GASTBY_DOCTOR_ENCRYPTED_KEY,
  GATSBY_DOCTOR_ENCRYPTED_VALUE,
} from "gatsby-env-variables"

const EnrollmentMechanics = props => {
  const { cta, nextPath, module } = props.pageContext
  const { state, dispatch } = useContext(AppContext)

  const nextLink = `/${module.name}/${nextPath}`

  const handleFormSubmit = values => {
    sessionStorage.setItem(
      GASTBY_DOCTOR_ENCRYPTED_KEY,
      GATSBY_DOCTOR_ENCRYPTED_VALUE
    )
    dispatch({
      type: "SAVE_ACKNOWLEDGEDMENT",
      payload: values.hasAcknowledge,
    })

    navigate(nextLink)
  }

  return (
    <Layout
      title={module.title}
      subtitle="Please provide consent to the terms and conditions before proceeding."
      seoTitle={`${module.seoTitle} Mechanics`}
    >
      <Container isCentered>
        <ConsentAndAuthorization
          module={module.name}
          handleOnSubmit={handleFormSubmit}
        >
          <ActionButtons
            back={{
              label: "Back",
              link: "/",
            }}
            submit={{
              label: cta,
              disabled: state?.consentAndAuth.length === 0,
            }}
          />
        </ConsentAndAuthorization>
        {module.subtitle && (
          <div className={classNames("is-flex is-justify-content-center mt-1")}>
            <section
              dangerouslySetInnerHTML={{ __html: module.subtitle }}
            ></section>
          </div>
        )}
      </Container>
    </Layout>
  )
}

export default EnrollmentMechanics
